import React from 'react';
import Web3 from 'web3';
import web3 from '../interface/web3';
import tokenSaleController from '../interface/tokenSaleController';
import Loader from '../images/Spinner.gif';
import { calculateTokenPriceInDollars, calculateTotalTokenPriceInEth } from '../smartContractFunc/functions';


export default class Box1 extends React.Component {
  constructor() {
    super();
    this.state = {
      input1: '',
      input2: '',
      input3: '',
      transactionHash: '',
      input1Error: null,
      input2Error: null,
      priceInDollar: 0,
      priceinEth: 0,
      loaderState: false
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onBuyClick = this.onBuyClick.bind(this);
  }

  async onInputChange(e, num) {
    if (num === '1') {
      this.setState({
        [`input${num}`]: (e.target.value).toLowerCase()
      })
    }
    else {
      this.setState({
        [`input${num}`]: e.target.value
      });
      if (num === '2') {
        this.setState({ loaderState: true })
        let totalAmount = await calculateTokenPriceInDollars(e.target.value)
        let totalEth = await calculateTotalTokenPriceInEth(totalAmount)
        this.setState({
          priceInDollar: totalAmount,
          priceinEth: totalEth,
          loaderState: false
        })
      }
    }


  }




  async componentDidMount() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        // Acccounts now exposed

      } catch (error) {
        // User denied account access...
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      window.web3 = new Web3(this.web3.currentProvider);
    }
    // Non-dapp browsers...
    else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  }

  investorCodeValidation(value) {
    let error = null;

    if (value.indexOf(' ') !== -1) {
      error = 'This field is required!';
    } else if (value === '') {
      error = 'This field is required!';
    }

    return error;
  }

  tokenValidation(value) {
    let error = null;

    if (value === '') {
      error = 'This field is required!';
    } else if (value < 1) {
      error = 'Must be greater than 0!';
    }

    return error;
  }

  // async calculateTokenPriceInDollars(amount) {
  //   let totalSupply = parseInt(await tokenController.methods.totalSupply().call());
  //   let totalAmountInDollar = 0;
  //   for (let i = 0; i < amount; i++) {
  //     if ((totalSupply + i) <= 1000) {
  //       totalAmountInDollar += 1000;
  //     }
  //     else if ((totalSupply + i) <= 2000) {
  //       totalAmountInDollar += 1500;
  //     }
  //     else if ((totalSupply + i) <= 3000) {
  //       totalAmountInDollar += 2000;
  //     }
  //     else if ((totalSupply + i) <= 4000) {
  //       totalAmountInDollar += 2500;
  //     }
  //     else if ((totalSupply + i) <= 5000) {
  //       totalAmountInDollar += 3000;
  //     }
  //   }
  //   return totalAmountInDollar
  // }

  // async calculateTotalTokenPriceInEth(priceInDollars) {
  //   if (priceInDollars === 0) {
  //     return 0;
  //   }
  //   let etherPrice = await tokenSaleController.methods.ETHUSD().call();
  //   let etherToSend = priceInDollars / etherPrice;
  //   etherToSend += +0.005;
  //   return etherToSend;

  // }

  async onBuyClick() {


    const { input1, input2, input3 } = this.state;

    const error1 = this.investorCodeValidation(input1);
    const error2 = this.tokenValidation(input2);

    if (error1 || error2) {
      return this.setState({ input1Error: error1, input2Error: error2 });
    } else {
      this.setState({ input1Error: null, input2Error: null });
    }

    let that = this;
    // WEB3

    const accounts = await web3.eth.getAccounts();

    // Setting beneficiary address

    // Getting total token price in dollars
    let totalAmountInDollar = await calculateTokenPriceInDollars(parseInt(this.state.input2));
    let etherToSendWithTx = await calculateTotalTokenPriceInEth(totalAmountInDollar);


    // Sending buy token transaction
    await tokenSaleController.methods.buyToken(accounts[0], parseInt(this.state.input2), (this.state.input1).toLowerCase()).send({
      from: accounts[0],
      value: web3.utils.toWei(etherToSendWithTx.toString(), 'ether'),
    }).on('transactionHash', (hash) => {
      that.setState({ transactionHash: 'https://rinkeby.etherscan.io/tx/' + hash })
      window.open(that.state.transactionHash, '_blank')
    }).on('confirmation', function (confirmationNumber, receipt) {
      console.log(confirmationNumber + ' ' + receipt);
      console.log("Transaction confirmed");
      return true;
    })

  }

  render() {
    const { input1, input2, input3, input1Error, input2Error } = this.state;
    const { priceInDollar } = this.state;
    return (
      <div>
        <div id="box-2">
          <div className="container-fluid col">
            <form>
              <div className="textBoxes-Styling">
                <div className="row" >
                  <div className="custom-input" >
                    <input
                      style={{ borderColor: "#B267CC", }}
                      type="text"
                      value={input1}
                      className="text-fields-width text-fields-font form-control form-control-lg"
                      placeholder="Access code"
                      onChange={e => this.onInputChange(e, '1')}
                    />
                    {input1Error && (
                      <small className="form-text text-muted">
                        {input1Error}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="custom-input" >
                    <input
                      style={{ borderColor: "#B267CC" }}
                      type="text"
                      value={input2}
                      className="text-fields-width text-fields-font form-control form-control-lg"
                      placeholder="Number of tokens"
                      onChange={e => this.onInputChange(e, '2')}
                    />
                    {input2Error && (
                      <small className="form-text text-muted">
                        {input2Error}
                      </small>
                    )}
                  </div>
                </div>
                {/* <div className="row"> */}
                {/* <div className="custom-input">
                    <input
                      style={{ borderColor: "#B267CC" }}
                      type="text"
                      value={input3}
                      className="text-fields-width text-fields-font form-control form-control-lg mb-4 "
                      placeholder="Beneficiary address (opt)"
                      onChange={e => this.onInputChange(e, '3')}
                    />
                  </div> */}
                {/* </div> */}
              </div>

              {/* <div className="row mt-5 block-margin">
                <div className="col"> */}
              <div className="row block-margin">
                <div className="col mb-4">
                  <h4 className="medium-14"><b>Your investment amount</b></h4>
                </div>

              </div>

              {(this.state.loaderState)
                ? (<img src={Loader} style={{ width: '10%' }} />)
                : (
                  <div className="col mb-4">
                    <div className="row">
                      <div className="col">
                        <h2 className="numeric-25">
                          <b>
                            $
                            {priceInDollar.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}

                            {
                        /* {this.props.investment
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                          </b>
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="medium-14">
                          ETH{' '}
                          {this.state.priceinEth.toFixed(2)}
                          {/* {(this.props.investment / 274)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              <div>
                <button
                  id="buy"
                  style={{ width: 320 }}
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={this.onBuyClick}
                >
                  BUY
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
