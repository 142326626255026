import React, { Component } from 'react';
import SelectInvestment from './SelectInvestment';
import SelectPlayers from './SelectPlayers';
import SelectSpots from './SelectSpots';
import { calculateTokenPriceInDollars } from '../smartContractFunc/functions';

export default class Box3 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      tokenPrice: 1000
    }
  }

  componentDidMount() {
    this.tokenPriceCalculator();
  }

  tokenPriceCalculator = async () => {
    let tokenPrice = await calculateTokenPriceInDollars(1);
    console.log(tokenPrice)
    this.setState({
      tokenPrice
    })
  }

  render() {
    const { investment, players, spots } = this.props;
    const { tokenPrice } = this.state;
    console.log(investment, players, spots, tokenPrice);
    let tokens = (investment / tokenPrice);
    let  profit = (players * 28* 0.05);
    profit += (spots * 25 * 0.05);
    profit = profit*(tokens/5000);
    if(isNaN(profit)){
      profit = 0;
    }

    return (
      <div>
        <div id="box-3">
          <div className="row mb-5">
            <div className="col section-heading" style={{ marginBottom: "40px !important" }}>
              Investment calculator
            </div>
          </div>

          <div className="row mb-2">
            <div className="col">
              <h4 className="medium-14"> <b>Your estimated profit</b> </h4>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col">
              <h2 className="numeric-25">
                <b>
                  ${profit.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </b>
              </h2>
            </div>
          </div>

          <div className="container-fluid col-md-7 line-space">
            <SelectInvestment
              investment={this.props.investment}
              func={this.props.setInvestment}
            />
          </div>


          <div className="container-fluid col-md-7 line-space">
            <SelectPlayers
              players={this.props.players}
              func={this.props.setPlayers}
            />
          </div>


          <div className="container-fluid col-md-7">
            <SelectSpots
              spots={this.props.spots}
              func={this.props.setSpots}
            />
          </div>
        </div>
      </div>
    );
  }
}
