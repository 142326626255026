import React from 'react';
import Select from 'react-select'
import './App.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});


const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: '45px' }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'blue' : 'black',
    padding: 0,
  }),
  // menu: (provided, state) => ({
  //   height: "100%"
  // }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? 'blue'
        : isFocused
          ? '#d6e6f8'
          : null,
      color: isSelected
        ? 'white'
        : 'black',
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },

}


export default function SimpleSelect(props) {

  const handleChange = (event) => {
    props.func(event.value);
  }

  const slideValues = [];
  for (let i = 1; i <= 10; i++) {
    slideValues.push({ value: Number(`${i}000`), label: (`$${i},000`) });
  }

  for (let i = 2; i <= 10; i++) {
    slideValues.push({ value: Number(`${i}0000`), label: (`${i}0,000`) });
  }

  for (let i = 2; i <= 10; i++) {
    slideValues.push({ value: Number(`${i}00000`), label: (`${i}00,000`) });
  }
  return (
    <>
      <label for="investment" style={{ display: 'flex', fontSize: '15px', fontWeight: 'bold' }}>
        Investment
      </label>
      <div>
        <Select
          id="investment"
          isSearchable={false}
          optionsType={[]}
          options={slideValues}
          onChange={handleChange}
          styles={customStyles}
          placeholder="Investment"
        />
      </div>
    </>
  );
}
