import React, { Component } from 'react';
import Box1 from './Box1';
import Box2 from './Box2';
import Box3 from './Box3';
import Box4 from './Box4';
import Box5 from './Box5';
import './App.css';

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      investment: 0,
      players: 0,
      spots: 0
    };

    this.setInvestment = this.setInvestment.bind(this);
    this.setPlayers = this.setPlayers.bind(this);
    this.setSpots = this.setSpots.bind(this);
  }

  setInvestment(value) {
    // console.log(value);
    this.setState({ investment: value });
  }

  setPlayers(value) {
    // console.log(value);
    this.setState({ players: value });
  }

  setSpots(value) {
    // console.log(value);
    this.setState({ spots: value });
  }

  render() {
    const { investment, players, spots, tokenPrice } = this.state;

    return (
      <div>
        <Box1 />
        <Box2 investment={investment} />
        <Box3
          investment={investment}
          players={players}
          spots={spots}
          setInvestment={this.setInvestment}
          setPlayers={this.setPlayers}
          setSpots={this.setSpots}
        />
        <Box4 />
        <Box5 />
      </div>
    );
  }
}
