import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'
var converter = require('number-to-words');


const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});


const customStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: '45px' }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'blue' : 'black',
    padding: 0,
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? 'blue'
        : isFocused
          ? '#d6e6f8'
          : null,
      color: isSelected
        ? 'white'
        : 'black',
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },

}


export default function SimpleSelect(props) {

  const handleChange = (event) => {
    console.log(event)
    props.func(event.value);
  }

  const slideValues = [];


  slideValues.push({ value: Number(`0`), label: (`0`) });

  for (let i = 1; i < 10; i++) {
    slideValues.push({ value: Number(`${i}000000`), label: (`${i} million`) });
  }

  for (let i = 1; i <= 10; i++) {
    slideValues.push({ value: Number(`${i}0000000`), label: (`${i * 10} million`) });
  }

  slideValues.push({ value: Number(`150000000`), label: (`150 million`) })
  slideValues.push({ value: Number(`2000000000`), label: (`200 million`) })

  return (
    <>
      <label for="spots" style={{ display: 'flex', fontSize: '15px', fontWeight: 'bold' }}>
        Spots
      </label>
      <Select
        id="spots"
        isSearchable={false}
        optionsType={[]}
        options={slideValues}
        onChange={handleChange}
        styles={customStyles}
        placeholder="Spots"
      />
    </>
  );
}
